export const OIDC = "OIDC";
export const NONE = "NONE";

export const SUCCESS_SEVERITY = "success";
export const ERROR_SEVERITY = "error";

export const VALINT_QUESTIONING = "VALINT";
export const VALPAP_QUESTIONING = "VALPAP";
export const PARTIELINT_QUESTIONING = "PARTIELINT";
export const HC_QUESTIONING = "HC";
export const REFUSAL_QUESTIONING = "REFUSAL";
